import Index from "./components/Index"
import "./bootstrap-grid.min.css";
import { BrowserRouter, Routes, Route, HashRouter } from "react-router-dom";

function App() {

  
  return (
    <HashRouter>
      <Routes>
        <Route path="/:id" element={<Index />} />
      </Routes>
    </HashRouter>
  )
}

export default App;
