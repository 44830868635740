import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';


axios.defaults.baseURL = "https://yazilimweb.com/satin-alma/TanitimApi"
// axios.defaults.baseURL = "http://192.168.1.162/e2/tanitimapi"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render( <App /> );
