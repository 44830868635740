import React from 'react'

function Skeleton() {
  return (
    <div className=''>
    <div className="bg-slate-200 mb-3 w-[30%] h-[30px] animate-pulse"></div>
    <div className="grid grid-cols-3 gap-2 mt-2 mb-5">
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
    </div>

    <div className="bg-slate-200 mb-3 w-[30%] h-[30px] animate-pulse"></div>
    <div className="grid grid-cols-3 gap-2 mt-2 mb-5">
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
    </div>

    <div className="bg-slate-200 mb-3 w-[30%] h-[30px] animate-pulse"></div>
    <div className="grid grid-cols-3 gap-2 mt-2 mb-5">
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
      <div className="bg-slate-200 h-[30px] animate-pulse"></div>
    </div>
  </div>
  )
}

export default Skeleton